export default {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    forgotPassword: '/auth/password-forgot',
    resetPassword: '/auth/password-reset/:token',
    invitationSignup: '/auth/invitations/signup',
  },
  public: {
    unitsPreview: '/rental-application/invitations/:inviteId',
    guestApply: '/units/:unitId/apply',
    waitingListApply: '/waiting-list/apply/:inviteId',
  },
  app: {
    comingSoon: '/app/coming-soon',
    organizations: '/app/organizations',
    phas: '/app/phas',
    addPha: '/app/pha/add',
    phaMembers: '/app/pha-members',
    phaInvitations: '/app/invitations',
    phaProgrammes: '/app/programmes',
    organizationMembers: '/app/organization-members',
    insuranedApplications: '/app/insuranced-applications',
    buildings: '/app/properties',
    applications: '/app/applications',
    waitListApplications: '/app/waitlist-application',
    createApplication: '/app/applications/create',
    application: (applicationId: number) =>
      `/app/applications/${applicationId}`,
    units: '/app/units',
    guestCard: '/app/guestCard',
    support: '/app/support',
    pendingPayrolls: '/app/support/pending-payrolls/:applicationId',
    pendingProperties: '/app/support/pending-properties/:organisationId',
    rejectedBankStatements:
      '/app/support/rejected_bank_statement/:applicationId',
    bankEntry: '/app/support/bank-entry',
    referrals: '/app/referrals',
    settings: '/app/settings',
    newApplications: '/app/new-applications',
    uploader: '/app/uploader',
    configuration: '/app/configuration',
    upload: {
      onboarding: '/overview/rental-application/units/:unitId',
      terms: '/rental-application/units/:unitId/terms-conditions',
      yourInformation: '/rental-application/units/:unitId/your-information',
      phaVoucher: '/rental-application/units/:unitId/pha-voucher',
      govtId: '/rental-application/units/:unitId/govtId',
      coapplicants:
        '/rental-application/units/:unitId/coapplications-guarantor',
      paymentDetails: '/rental-application/units/:unitId/payment-details',
      crs: '/rental-application/units/:unitId/credit-report',
      bankStatements: '/rental-application/units/:unitId/bank-statements',
      employmentInformation:
        '/rental-application/units/:unitId/employment-information',
      taxReturns: '/rental-application/units/:unitId/tax-returns',
      residenceHistory: '/rental-application/units/:unitId/residence-history',
      submission: '/rental-application/units/:unitId/submission',
    },
    waitingList: {
      create: '/app/waiting-list/create',
      apply: {
        landing: '/waiting-list/apply/',
        termsAndCondition: '/waiting-list/apply/:inviteId/terms-and-condition',
        yourInformation: '/waiting-list/apply/:inviteId/your-information',
        govtId: '/waiting-list/apply/:inviteId/govt-id',
        bankStatements: '/waiting-list/apply/:inviteId/bank-statements',
        employmentInformation:
          '/waiting-list/apply/:inviteId/employment-information',
        additionalDocuments:
          '/waiting-list/apply/:inviteId/additional-documents',
      },
      waitlist: '/app/waitlist',
      list: '/app/waitlist-applications',
      show: (id: number): string => `/app/waitlist-applications/${id}`,
      invitesList: '/app/waitlist/invites',
    },
    suggestions: '/app/property-suggestions',
    viewSuggestion: '/app/property-suggestions/:applicationId/unit/:unitId',
    systemLogs: '/app/systemLogs',
    tenantMonitoring: '/app/waiting-list',
    getInsurence: '/app/insurence',
  },
  user: {
    profile: {
      view: '/user/:user_id',
      edit: '/user/:user_id/edit',
    },
    phaUserInvitation: '/users/invitations/:phaInviteId',
  },
  documents: {
    confirmDetails: '/documents/:appId/confirm-details',
    insurance: '/documents/:appId/insurance-subscription',
    signLease: '/documents/:appId/sign-lease',
  },
};
