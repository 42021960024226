/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action, observable } from 'mobx';
import { notification } from 'antd';
import { PHAInviteAnalytics } from 'models/entities/PHAInviteAnalyticsModal';
import { ModelList } from 'models/ModelList';
import { PHAProgramme } from 'models/entities/PHAProgrammesModel';
import Store from './Store';
import { PHA } from '../models/entities/PHAModel';
import { PaginatedModelList } from '../models/PaginatedModelList';
import ApiRoutes from '../routes/api/ApiRoutes';
import { PHAInvitation } from '../models/entities/PHAInvitationModel';
import { ModelItem } from '../models/ModelItem';
import { User } from 'models/entities/UserModel';
import { Application } from 'models/entities/ApplicationModel';
import { UserStore } from './UserStore';

export class PHAStore extends Store<PHA> {
  constructor() {
    super();
    PHA._store = this;
    PHAInvitation._store = this;
    PHAProgramme._store = this;
  }

  @observable phaPaginatedList = new PaginatedModelList<PHA>(PHA);

  @observable phaMembersPaginatedList = new PaginatedModelList<User>(User);

  @observable phaItem = new ModelItem<PHA>(PHA);

  @observable phaInvitationsPaginatedList = new PaginatedModelList<
    PHAInvitation
  >(PHAInvitation);

  @observable phaInvitationItem = new ModelItem<PHAInvitation>(PHAInvitation);

  @observable phaInviteAnalytics = new ModelList<PHAInviteAnalytics>(
    PHAInviteAnalytics,
  );

  @observable phaProgrammesPaginatedList = new PaginatedModelList<PHAProgramme>(
    PHAProgramme,
  );

  @observable phaSubProgrammesPaginatedList = new PaginatedModelList<
    PHAProgramme
  >(PHAProgramme);

  @observable phaAllSubProgrammesPaginatedList = new PaginatedModelList<
    PHAProgramme
  >(PHAProgramme);

  @observable selectedPhaIdForMemberFilter = undefined;

  @observable loadingMembers = false;

  get phas() {
    return this.phaPaginatedList.items;
  }

  @action
  setSelectedPhaId(val: number | undefined) {
    this.selectedPhaIdForMemberFilter = val;
  }

  @action
  async loadPHAs(params?: { [key: string]: any }) {
    try {
      this.phaPaginatedList.setLoading(true);
      await this.phaPaginatedList.load(ApiRoutes.phas.list, params);
      this.phaPaginatedList.setLoading(false);
    } catch (e) {
      this.phaPaginatedList.setLoading(false);
    }
  }
  @action
  async getPHA(pha_id: number, appendToPaginatedList = false) {
    try {
      this.phaItem.setLoading(true);
      const response = await this.apiService.get(ApiRoutes.phas.show(pha_id));
      if (response.data) {
        this.phaItem.setItem(response.data);
        if (appendToPaginatedList) {
          const pha = PHA.getOrNew(pha_id) as PHA;
          pha.updateFromJson(response.data);
          this.phaPaginatedList.setItems([pha, ...this.phaPaginatedList.items]);
        }
      }
    } catch (e) {
      notification.error({
        message: e?.message || 'Something went wrong',
        duration: 2.5,
      });
    } finally {
      this.phaItem.setLoading(false);
    }
  }
  @action async updatePHA(
    pha_id: number,
    data: {
      primary_name: string;
      primary_contact: string;
      city_name: string;
      street_name: string;
      state_code: string;
      state?: string;
      house_number: string;
      country: string;
      zip_code: string | number;
      latitude: number;
      longitude: number;
      step_cofiguration?: {
        rta_enabled: boolean;
        comps_selection_enabled: boolean;
        rent_determination_enabled: boolean;
        inspection_enabled: boolean;
        contract_enabled: boolean;
      };
    },
  ) {
    try {
      this.phaItem.setLoading(true);
      const response = await this.apiService.patch(
        ApiRoutes.phas.show(pha_id),
        data,
      );
      if (response.data) {
        this.phaItem.setItem(response.data);
      }
      this.phaItem.setLoading(false);
      return response.data;
    } catch (e) {
      this.phaItem.setLoading(false);
      notification.error({
        message: e?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  async loadPhaMembers(phaId: number, params?: any): Promise<void> {
    await this.phaMembersPaginatedList.load(
      ApiRoutes.phas.members.list(phaId),
      params,
    );
  }

  @action
  async fetchPhaFromId(id: number, params?: { [key: string]: any }) {
    const pha = this.phas.find((_pha) => _pha.id === id);
    if (pha) {
      this.phaItem.setItem(pha);
      return pha;
    }
    await this.phaItem.load(ApiRoutes.phas.show(id), params);

    this.phaPaginatedList.appendItem(this.phaItem.item);

    return this.phaItem.item;
  }

  @action
  async createPhaMember(phaId: number, data: any) {
    const response = await this.apiService.post(
      ApiRoutes.phas.members.create(phaId),
      data,
    );
    if (this.selectedPhaIdForMemberFilter === phaId) {
      const member = User.fromJson(response.data) as User;
      this.phaMembersPaginatedList.appendItem(member);
      return member;
    }
    return null;
  }

  @action
  async updatePhaMember(phaId: number, memberId: number, data: any) {
    const response = await this.apiService.put(
      ApiRoutes.phas.members.update(phaId, memberId),
      data,
    );
    this.phaMembersPaginatedList.items
      .find((_item) => _item.id === memberId)
      .updateFromJson(response.data);
  }

  @action
  async deletePhaMember(phaId: number, memberId: number, isOwner = false) {
    await this.apiService.delete(
      ApiRoutes.phas.members.update(phaId, memberId),
    );
    if (isOwner) {
      this.phaMembersPaginatedList = new PaginatedModelList<User>(User);
      const newPhas = this.phaPaginatedList.items.filter(
        (_pha) => _pha.id !== phaId,
      );
      this.phaPaginatedList.setItems(newPhas);
      this.setSelectedPhaId(newPhas[0].id);
    } else {
      const newItems = this.phaMembersPaginatedList.items.filter(
        (_item) => _item.id !== memberId,
      );
      this.phaMembersPaginatedList.setItems(newItems);
    }
  }

  @action
  async loadPHAInvites(params?: { [key: string]: any }) {
    try {
      this.phaInvitationsPaginatedList.setLoading(true);
      await this.phaInvitationsPaginatedList.load(
        ApiRoutes.phas.invites.list,
        params,
      );
      this.phaInvitationsPaginatedList.setLoading(false);
    } catch (e) {
      this.phaInvitationsPaginatedList.setLoading(false);
    }
  }

  @action async sendInvite(pha_id: number, data: any) {
    try {
      this.phaInvitationsPaginatedList.setLoading(true);
      const response = await this.apiService.post(
        ApiRoutes.phas.invites.create(pha_id),
        { invite_data: data },
      );
      this.phaInvitationsPaginatedList.items.unshift(response?.data);
      this.phaInvitationsPaginatedList.setLoading(false);
      return response.data;
    } catch (e) {
      this.phaInvitationsPaginatedList.setLoading(false);
      notification.error({
        message: e?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  async bulkInvite(phaId: number, inviteData: any) {
    try {
      const response = await this.apiService.post(
        ApiRoutes.phas.invites.bulkCreate(phaId),
        {
          invite_data: inviteData,
        },
      );
      if (response && response.data) {
        const data = [
          ...response.data,
          ...this.phaInvitationsPaginatedList.items,
        ];
        this.phaInvitationsPaginatedList.setItems(data);
      }
    } catch (e) {
      notification.error({
        message: e?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  async fetchPHAInvite(invite_id: string): Promise<void> {
    try {
      this.phaInvitationItem.setLoading(true);
      const response = await this.apiService.get(
        ApiRoutes.public.phaInvite.show(invite_id),
      );
      if (response) {
        this.phaInvitationItem.setItem(response.data);
        this.phaInvitationItem.setLoading(false);
        this.phaInvitationItem.setLoaded(true);
      }
    } catch (e) {
      this.phaInvitationItem.setLoading(false);
      this.phaInvitationItem.setLoaded(false);
      notification.error({
        message: e?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action async answerQuestionnaire(data: {}, phaInvitedId: string) {
    return this.apiService.post(
      ApiRoutes.phas.invites.answerQuestionnaire(phaInvitedId),
      data,
    );
  }

  @action async getAnalytics(invite_id: number) {
    try {
      this.phaInviteAnalytics.setLoading(true);
      const response = await this.apiService.get(
        ApiRoutes.phas.analytics(invite_id),
      );
      if (response) {
        this.phaInviteAnalytics.setLoading(false);
        this.phaInviteAnalytics.setLoaded(true);
        this.phaInviteAnalytics.setItems(response.data);
      }
    } catch (e) {
      this.phaInviteAnalytics.setLoading(false);
      this.phaInviteAnalytics.setLoaded(false);
      notification.error({
        message: e?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action async createPHA(data: {
    name: string;
    primary_contact_name: string;
    email: string;
    primary_phone: string;
    city_name: string;
    street_name: string;
    state_code: string;
    state?: string;
    house_number: string;
    country: string;
    zip_code: string | number;
    latitude: number;
    longitude: number;
    step_cofiguration?: {
      rta_enabled: boolean;
      comps_selection_enabled: boolean;
      rent_determination_enabled: boolean;
      inspection_enabled: boolean;
      contract_enabled: boolean;
    };
  }): Promise<any> {
    try {
      const response = await this.apiService.post(ApiRoutes.phas.list, data);
      if (response.data) {
        const pha = PHA.fromJson(response.data) as PHA;
        this.phaItem.setItem(pha);
        this.phaPaginatedList.appendItem(pha);
      }
      return response.data;
    } catch (e) {
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  async createProgramme(
    pha_id: number,
    programme_data: { name: string; code: string },
  ) {
    this.phaProgrammesPaginatedList.setLoading(true);
    const response = await this.apiService.post(
      ApiRoutes.phas.programmes.createAndList(pha_id),
      { code: programme_data.code, name: programme_data.name },
    );
    if (response.data) {
      const programme = PHAProgramme.fromJson(response.data) as PHAProgramme;
      this.phaProgrammesPaginatedList.appendItem(programme);
    }
    this.phaProgrammesPaginatedList.setLoading(false);
    return response.data;
  }

  async createSubProgramme(
    pha_id: number,
    subProgramme_data: { name: string; code: string },
  ) {
    this.phaSubProgrammesPaginatedList.setLoading(true);
    const response = await this.apiService.post(
      ApiRoutes.phas.subProgrammes.createAndList(pha_id),
      { code: subProgramme_data.code, name: subProgramme_data.name },
    );
    if (response.data) {
      const subProgramme = PHAProgramme.fromJson(response.data) as PHAProgramme;
      this.phaSubProgrammesPaginatedList.appendItem(subProgramme);
    }
    this.phaSubProgrammesPaginatedList.setLoading(false);
    return response.data;
  }

  @action
  async listProgrammes(pha_id: number) {
    try {
      this.phaProgrammesPaginatedList.setLoading(true);
      const response = await this.apiService.get(
        ApiRoutes.phas.programmes.createAndList(pha_id),
      );
      if (response.data) {
        this.phaProgrammesPaginatedList.setItems(response.data);
      }
      this.phaProgrammesPaginatedList.setLoading(false);
    } catch (e) {
      this.phaProgrammesPaginatedList.setLoading(false);
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  async listSubProgrammes(pha_id: number) {
    try {
      this.phaSubProgrammesPaginatedList.setLoading(true);
      const response = await this.apiService.get(
        ApiRoutes.phas.subProgrammes.createAndList(pha_id),
      );
      if (response.data) {
        this.phaSubProgrammesPaginatedList.setItems(response.data);
      }
      this.phaSubProgrammesPaginatedList.setLoading(false);
    } catch (e) {
      this.phaSubProgrammesPaginatedList.setLoading(false);
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  async listAllSubProgrammes() {
    try {
      this.phaAllSubProgrammesPaginatedList.setLoading(true);
      const response = await this.apiService.get(
        ApiRoutes.phas.subProgrammes.getAllSubProgrammes,
      );
      if (response.data) {
        this.phaAllSubProgrammesPaginatedList.setItems(response.data);
      }
      this.phaAllSubProgrammesPaginatedList.setLoading(false);
    } catch (e) {
      this.phaAllSubProgrammesPaginatedList.setLoading(false);
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  // eslint-disable-next-line consistent-return
  @action
  async updateProgramme(
    pha_id: number,
    programme_id: number,
    programme_data: { code?: string; name?: string },
  ) {
    try {
      const response = await this.apiService.put(
        ApiRoutes.phas.programmes.updateAndRemove(pha_id, programme_id),
        { code: programme_data.code, name: programme_data.name },
      );
      const programme = PHAProgramme.getOrNew(programme_id) as PHAProgramme;

      programme.updateFromJson(response.data);

      const newProgrammesList = this.phaProgrammesPaginatedList.items.map(
        (_programme) => {
          if (_programme.id === programme_id) {
            return programme;
          }
          return _programme;
        },
      );

      this.phaProgrammesPaginatedList.setItems(newProgrammesList);
      return response.data;
    } catch (e) {
      console.log(e);
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  @action
  async updateSubProgramme(
    pha_id: number,
    sub_programme_id: number,
    sub_programme_data: { code?: string; name?: string },
  ) {
    try {
      const response = await this.apiService.put(
        ApiRoutes.phas.subProgrammes.updateAndRemove(pha_id, sub_programme_id),
        { code: sub_programme_data.code, name: sub_programme_data.name },
      );
      const subProgramme = PHAProgramme.getOrNew(
        sub_programme_id,
      ) as PHAProgramme;

      subProgramme.updateFromJson(response.data);

      const newSubProgrammesList = this.phaSubProgrammesPaginatedList.items.map(
        (_subProgramme) => {
          if (_subProgramme.id === sub_programme_id) {
            return subProgramme;
          }
          return _subProgramme;
        },
      );

      this.phaSubProgrammesPaginatedList.setItems(newSubProgrammesList);
      return response.data;
    } catch (e) {
      console.log(e);
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  async deleteProgramme(pha_id: number, programme_id: number) {
    try {
      const response = await this.apiService.delete(
        ApiRoutes.phas.programmes.updateAndRemove(pha_id, programme_id),
      );
      const newProgrammes = this.phaProgrammesPaginatedList.items.filter(
        (_programme) => _programme.id !== programme_id,
      );
      this.phaProgrammesPaginatedList.setItems(newProgrammes);

      return response;
    } catch (e) {
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  async deleteSubProgramme(pha_id: number, sub_programme_id: number) {
    try {
      const response = await this.apiService.delete(
        ApiRoutes.phas.subProgrammes.updateAndRemove(pha_id, sub_programme_id),
      );
      const newSubProgrammes = this.phaSubProgrammesPaginatedList.items.filter(
        (_subProgramme) => _subProgramme.id !== sub_programme_id,
      );
      this.phaSubProgrammesPaginatedList.setItems(newSubProgrammes);

      return response;
    } catch (e) {
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  async getProgramme(pha_id: number, programme_code: string) {
    try {
      const response = await this.apiService.post(
        ApiRoutes.phas.programmes.getProgramme(pha_id),
        { programme_code },
      );
      return response.data;
    } catch (e) {
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  async getSubProgramme(pha_id: number, sub_programme_code: string) {
    try {
      const response = await this.apiService.post(
        ApiRoutes.phas.subProgrammes.getSubProgramme(pha_id),
        { sub_programme_code },
      );
      return response.data;
    } catch (e) {
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  async getSubProgrammeById(id: number) {
    try {
      const response = await this.apiService.get(
        ApiRoutes.phas.subProgrammes.getById(id),
      );
      this.phaAllSubProgrammesPaginatedList.appendItem(response.data);
      return response.data;
    } catch (e) {
      notification.error({
        message: (e as any)?.message || 'Something went wrong',
        duration: 2.5,
      });
    }
  }

  async updateTermsAndConditions(
    organisation_id: number,
    terms_accepted_at: Date,
    signatorName: string,
  ) {
    const response = await this.apiService.post(
      ApiRoutes.phas.invites.updateTermsAnditions(organisation_id),
      { terms_accepted_at, signatorName },
    );
    return response.data;
  }

  async submitW9Form(application: Application, data: any) {
    const response = await this.apiService.post(
      ApiRoutes.phas.w9Form(application.id),
      data,
    );
    return response.data;
  }

  @action
  clearStore(): void {
    this.phaPaginatedList = new PaginatedModelList<PHA>(PHA);
    this.phaInvitationsPaginatedList = new PaginatedModelList<PHAInvitation>(
      PHAInvitation,
    );
    this.phaInvitationItem = new ModelItem<PHAInvitation>(PHAInvitation);
    this.phaInviteAnalytics = new ModelList<PHAInviteAnalytics>(
      PHAInviteAnalytics,
    );
    this.phaSubProgrammesPaginatedList = new PaginatedModelList<PHAProgramme>(
      PHAProgramme,
    );
  }
}
