import { observable } from 'mobx';
import Model from '../Model';
import { PHAStore } from '../../stores/PHAStore';
import { PHAInviteType } from '../../enums/PHA/PHAInviteType.enum';
import { PHAInviteStatus } from '../../enums/PHA/PHAInviteStatus.enum';

export class PHAInvitation extends Model {
  static _store: PHAStore;

  @observable id: number;

  @observable pha_id: number;

  @observable invited_by: number;

  @observable receiver_type: PHAInviteType;

  @observable receiver_first_name: string;

  @observable receiver_last_name: string;

  @observable receiver_email: string;

  @observable receiver_phone_number: string;

  @observable company_name: string;

  @observable status: PHAInviteStatus;

  @observable createdAt: string;
}
